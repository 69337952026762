import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Jerk 2-2-2-2-2\\@90% 1RM`}</p>
    <p>{`Weighted Strict Pullups 2-2-2-2-2`}</p>
    <p>{`then,`}</p>
    <p>{`9:00 AMRAP of:`}</p>
    <p>{`3-S2OH (115/75)`}</p>
    <p>{`3-Pullups`}</p>
    <p>{`6-S2OH`}</p>
    <p>{`6-Pullups`}</p>
    <p>{`etc.`}</p>
    <p><strong parentName="p">{`*`}{`This Saturday we’ll have free Derby Day classes at 9:00 & 10:00am!`}</strong></p>
    <p><strong parentName="p">{`*`}{`If you’d like a Murph shirt please put your name on the sheet next
to the white board.  We will need to order them soon.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      